import english from "./locales/en";
import french from "./locales/fr";
import { defineI18nConfig } from "#imports";

export default defineI18nConfig(() => ({
  legacy: false,
  locale: "en",
  warnHtmlMessage: false,
  messages: {
    en: english,
    fr: french,
  },
}));
